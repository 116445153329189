/* Variables accessible to all stylesheets */
:root {
    --fm-blue: #005598;
    --fm-lightblue: #6fb9e1;
    --fm-blue-trans: #005598bb;
    --fm-lightblue-trans: #6fb9e1bb;
    --ls-blue: #00a3e0;
    --md-red: #da291c;
}

/* Import all custom stylesheets */
@import './partials/utils.css';
@import './partials/about-us.css';
@import './partials/categories.css';
@import './partials/footer.css';
@import './partials/forms.css';
@import './partials/general.css';
@import './partials/grid.css';
@import './partials/home.css';
@import './partials/other-pages.css';
@import './partials/lifesense.css';
@import './partials/modals.css';
@import './partials/my-account.css';
@import './partials/profiles.css';
@import './partials/sliders.css';
@import './partials/steadysense.css';

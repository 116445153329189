.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 70%);
    z-index: 99999;
    opacity: 0;
    transition: opacity 300ms ease-in;
    pointer-events: none;
}

.modal:target {
    opacity: 1;
    pointer-events: auto;
}

.modal-wrap {
    width: 500px;
    max-width: 90%;
    height: 500px;
    max-height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 25px;
    overflow: hidden;
}

.modal-body {
    position: relative;
    width: 90%;
    height: 80%;
    top: 10%;
    left: 5%;
    overflow-y: scroll;
}

.modal-close {
    font-size: 30px;
    right: 5%;
    top: 10px;
    text-decoration: none;
}

.modal-close:hover {
    color: var(--fm-lightblue);
}
/* Custom flexbox-based layout; a customised Bootstrap implementation. */

.rw {
    display: flex;
    flex-wrap: wrap;
}

.rw,
.cl,
[class*="cl-"],
[class*="rw-"] {
    box-sizing: border-box;
}

.cl,
[class*="cl-"] {
    padding: 5px;
}

.cl-1,
.cl-2,
.cl-3,
.cl-4,
.cl-5,
.cl-6,
.cl-7,
.cl-8,
.cl-9,
.cl-10,
.cl-11,
.cl-12,
.cl,
.cl-auto,
.cl-sm-1,
.cl-sm-2,
.cl-sm-3,
.cl-sm-4,
.cl-sm-5,
.cl-sm-6,
.cl-sm-7,
.cl-sm-8,
.cl-sm-9,
.cl-sm-10,
.cl-sm-11,
.cl-sm-12,
.cl-sm,
.cl-sm-auto,
.cl-md-1,
.cl-md-2,
.cl-md-3,
.cl-md-4,
.cl-md-5,
.cl-md-6,
.cl-md-7,
.cl-md-8,
.cl-md-9,
.cl-md-10,
.cl-md-11,
.cl-md-12,
.cl-md,
.cl-md-auto,
.cl-lg-1,
.cl-lg-2,
.cl-lg-3,
.cl-lg-4,
.cl-lg-5,
.cl-lg-6,
.cl-lg-7,
.cl-lg-8,
.cl-lg-9,
.cl-lg-10,
.cl-lg-11,
.cl-lg-12,
.cl-lg,
.cl-lg-auto,
.cl-xl-1,
.cl-xl-2,
.cl-xl-3,
.cl-xl-4,
.cl-xl-5,
.cl-xl-6,
.cl-xl-7,
.cl-xl-8,
.cl-xl-9,
.cl-xl-10,
.cl-xl-11,
.cl-xl-12,
.cl-xl,
.cl-xl-auto {
    position: relative;
    width: 100%;
}

.cl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.cl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.cl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.cl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.cl-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.cl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.cl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.cl-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.cl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.cl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.cl-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.cl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.cl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.cl-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.offset-1 {
    margin-left: 8.333333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.333333%;
}

.offset-5 {
    margin-left: 41.666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333333%;
}

.offset-8 {
    margin-left: 66.666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333333%;
}

.offset-11 {
    margin-left: 91.666667%;
}

@media (min-width: 576px) {
    .cl-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .cl-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .cl-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .cl-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .cl-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .cl-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .cl-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .cl-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .cl-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .cl-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .cl-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .cl-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .cl-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .cl-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.333333%;
    }

    .offset-sm-2 {
        margin-left: 16.666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.333333%;
    }

    .offset-sm-5 {
        margin-left: 41.666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.333333%;
    }

    .offset-sm-8 {
        margin-left: 66.666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.333333%;
    }

    .offset-sm-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 768px) {
    .cl-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .cl-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .cl-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .cl-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .cl-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .cl-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .cl-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .cl-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .cl-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .cl-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .cl-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .cl-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .cl-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .cl-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.333333%;
    }

    .offset-md-2 {
        margin-left: 16.666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.333333%;
    }

    .offset-md-5 {
        margin-left: 41.666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.333333%;
    }

    .offset-md-8 {
        margin-left: 66.666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.333333%;
    }

    .offset-md-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 992px) {
    .cl-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .cl-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .cl-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .cl-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .cl-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .cl-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .cl-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .cl-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .cl-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .cl-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .cl-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .cl-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .cl-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .cl-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.333333%;
    }

    .offset-lg-2 {
        margin-left: 16.666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.333333%;
    }

    .offset-lg-5 {
        margin-left: 41.666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.333333%;
    }

    .offset-lg-8 {
        margin-left: 66.666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.333333%;
    }

    .offset-lg-11 {
        margin-left: 91.666667%;
    }
}

@media (min-width: 1200px) {
    .cl-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .cl-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .cl-xl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .cl-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .cl-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .cl-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .cl-xl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .cl-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .cl-xl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .cl-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .cl-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .cl-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .cl-xl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .cl-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.333333%;
    }

    .offset-xl-2 {
        margin-left: 16.666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.333333%;
    }

    .offset-xl-5 {
        margin-left: 41.666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.333333%;
    }

    .offset-xl-8 {
        margin-left: 66.666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.333333%;
    }

    .offset-xl-11 {
        margin-left: 91.666667%;
    }
}